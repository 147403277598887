import React, { useEffect, useState, useContext, useRef } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import Layout from "../components/Layout"
import Loader from "../components/ui/Loader"
import { useLocation } from "@reach/router"
import { BrinkContext } from "../components/context/BrinkContext"
import { subscribeToNewsletter } from "../components/context/utils/events/klaviyo"
import * as events from "../components/context/utils/events"

const Container = styled.div`
  max-width: 70rem;
  margin: 5rem auto 0;
  padding: 0;
`

const ErrorMessage = styled.div`
  color: ${(p) => p.theme.colors.errors};
  border: 0.1rem solid ${(p) => p.theme.colors.errors};
  padding: 1.5rem 2rem;
  letter-spacing: 0;
  margin-bottom: 2rem;
  width: 100%;
  line-height: 1.8rem;
`

const Confirmation = styled.div`
  min-height: 40rem;
`

const KlarnaConfirmation = ({ snippet }) => {
  const kcoRef = useRef(null)
  const useMountEffect = (fun) =>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(fun, [])

  useMountEffect(() => {
    const scriptsTags = kcoRef.current.getElementsByTagName("script")
    // This is necessary otherwise the scripts tags are not going to be evaluated
    for (let i = 0; i < scriptsTags.length; i++) {
      const parentNode = scriptsTags[i].parentNode
      const newScriptTag = document.createElement("script")
      newScriptTag.type = "text/javascript"
      newScriptTag.text = scriptsTags[i].text
      parentNode.removeChild(scriptsTags[i])
      parentNode.appendChild(newScriptTag)
    }
  })

  return (
    <Confirmation ref={kcoRef} dangerouslySetInnerHTML={{ __html: snippet }} />
  )
}

const SuccessKlarnaPage = ({ pageContext }) => {
  const { t } = useTranslation("translations")
  const {
    cart,
    closeCart,
    getKlarnaOrder,
    discountCode,
    currentStore,
    brinkSessionId,
    deleteDiscountCode,
    deleteDiscountCodePopup,
    listName
  } = useContext(BrinkContext)

  const [isLoading, setIsLoading] = useState(true)
  const [displayError, setDisplayError] = useState(false)
  const [klarnaSnippet, setKlarnaSnippet] = useState(null)
  const location = useLocation()
  const klarnaOrderId = new URLSearchParams(location.search).get(
    "klarnaOrderId"
  )

  useEffect(() => {
    if (!brinkSessionId || !klarnaOrderId) return

    const loadConfirmation = async () => {
      const klarnaOrder = await getKlarnaOrder(klarnaOrderId)
      const {
        html_snippet: klarnaSnippet,
        billing_address: { email },
        merchant_requested: { additional_checkbox: newsletterChecked } = {}
      } = klarnaOrder

      events.purchase({
        klarnaOrder,
        discountCode,
        cart,
        currentStore,
        listName
      })
      setKlarnaSnippet(klarnaSnippet)
      if (newsletterChecked) {
        subscribeToNewsletter(currentStore.countryCode, email)
      }
    }

    setIsLoading(true)
    loadConfirmation()
      .catch(() => setDisplayError(true))
      .finally(() => {
        closeCart()
        setIsLoading(false)
        deleteDiscountCode()
        deleteDiscountCodePopup()
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brinkSessionId])

  if (!klarnaOrderId) return null

  return (
    <Layout
      meta={{ title: "Rondure" }}
      invertedHeader
      hideCountrySelector
      pageContext={pageContext}
    >
      <Container>
        {isLoading && <Loader isLoading={isLoading} />}
        {displayError && (
          <ErrorMessage>
            {t(
              "Something went wrong with. Please try another option or contact our support."
            )}
          </ErrorMessage>
        )}
        {klarnaSnippet && <KlarnaConfirmation snippet={klarnaSnippet} />}
      </Container>
    </Layout>
  )
}

export default SuccessKlarnaPage
